/**
 * ************************************
 *
 * @module index.ts
 * @author Adam Joesten
 * @date 6/15/22
 * @description A place to combine reducers
 *
 * ************************************
 */

import { combineReducers } from 'redux';
import { api as hasuraBaseApi } from './services/hasuraBaseApi';
import { api as eRelayBidBaseApi } from './services/eRelayBidBaseApi';
import { api as reportingBaseApi } from './services/reportingBaseApi';

import supplyReducer from '../features/supply/supplySlice';
import navigationReducer from '../features/navigation/navigationSlice';
import accountsReducer from '../features/accounts/accountsSlice';

const rootReducer = combineReducers({
  [hasuraBaseApi.reducerPath]: hasuraBaseApi.reducer,
  [eRelayBidBaseApi.reducerPath]: eRelayBidBaseApi.reducer,
  [reportingBaseApi.reducerPath]: reportingBaseApi.reducer,
  supplyReducer,
  navigationReducer,
  accountsReducer,
});

// export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
