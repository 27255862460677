import { getIdToken } from './getIdToken';
import { parseJwt } from './parseJWT';

export const getClaim = (key: string) => {
  let idToken = getIdToken();
  if (idToken !== undefined) {
    let parsedJWT = parseJwt(idToken);
    return parsedJWT[key];
  }
};
