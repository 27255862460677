import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import {
  AdminCreateUserCommandInput,
  AttributeType,
  CognitoIdentityProvider,
} from '@aws-sdk/client-cognito-identity-provider';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useGetAllAccountsQuery } from './api/GetAllAccounts.generated';
import { Auth } from 'aws-amplify';

interface AddUserProps {
  roles:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUser = ({ roles, setNewUser }: AddUserProps) => {
  const [session, setSession] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      setSession(res);
    };
    fetchData();
  }, []);
  const { data: useGetAllAccountsData } = useGetAllAccountsQuery();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [externalID, setExternalID] = useState<string>('');
  useEffect(() => {
    if (role === '' && roles !== undefined) {
      setRole(roles[0].name);
    }
  }, [role, roles]);
  useEffect(() => {
    if (account === undefined && useGetAllAccountsData !== undefined) {
      setAccount(useGetAllAccountsData?.account[0].name);
    }
  }, [account, useGetAllAccountsData]);

  const userAttributes: AttributeType[] = [
    { Name: 'custom:role', Value: role },
    {
      Name: 'custom:external_id',
      Value: externalID.length > 0 ? externalID : '',
    },
    {
      Name: 'custom:account_id',
      Value:
        account &&
        useGetAllAccountsData?.account.filter((acc) => acc.name === account)[0]
          .id,
    },
    { Name: 'name', Value: `${firstName} ${lastName}` },
    { Name: 'email', Value: email },
  ];
  const input: AdminCreateUserCommandInput = {
    DesiredDeliveryMediums: ['EMAIL'],
    UserAttributes: userAttributes,
    UserPoolId: 'us-east-1_2mIqjMWAc',
    Username: email,
  };
  const cognito = new CognitoIdentityProvider({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({
        region: 'us-east-1',
      }),
      identityPoolId: 'us-east-1:d2923628-6a97-45ab-b188-3fb94c633b55',
      logins: {
        'cognito-idp.us-east-1.amazonaws.com/us-east-1_2mIqjMWAc':
          session && session.signInUserSession.idToken.jwtToken,
      },
    }),
  });
  return (
    <div className="mt-5">
      <form>
        <div className="overflow-hidden border">
          <div className="bg-white px-4 py-5">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-black"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 pl-2 outline-none"
                />
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-black"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="family-name"
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 pl-2 outline-none"
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-black"
                >
                  Email address
                </label>
                <input
                  type="text"
                  name="email-address"
                  id="email-address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 pl-2 outline-none"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="access-level"
                  className="block text-sm font-medium text-black"
                >
                  Access Level
                </label>
                <select
                  id="access-level"
                  name="access-level"
                  autoComplete="access-level-name"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 bg-white py-2 px-3 pl-2 outline-none focus:outline-none"
                >
                  {roles &&
                    roles.map((role, idx) => (
                      <option key={`role-${idx}`}>{role.name}</option>
                    ))}
                </select>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="account"
                  className="block text-sm font-medium text-black"
                >
                  Account
                </label>
                <select
                  id="account"
                  name="account"
                  autoComplete="account-name"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 bg-white py-2 px-3 pl-2 outline-none focus:outline-none"
                >
                  {useGetAllAccountsData?.account &&
                    useGetAllAccountsData?.account.map((account, idx) => (
                      <option key={`name-${idx}`}>{account.name}</option>
                    ))}
                </select>
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="external-id"
                  className="block text-sm font-medium text-black"
                >
                  External ID
                </label>
                <input
                  type="text"
                  name="external-id"
                  id="external-id"
                  value={externalID}
                  onChange={(e) => setExternalID(e.target.value)}
                  autoComplete="external-id"
                  className="mt-1 block w-full rounded-lg border border-relay-grey-300 pl-2 outline-none"
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center rounded-lg border border-transparent bg-relay-blue-500 py-2 px-4 text-sm text-white focus:outline-none"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  setNewUser(false);
                  const response = await cognito.adminCreateUser(input);
                } catch (e) {
                  throw new Error(String(e));
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
