import { useEffect, useState } from 'react';
import TeamPermissions from './UserTeam';
import UserAccount from './UserAccount';
import { Auth } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import classNames from '../../utils/tailwind/classNames';
import { switchCurrentTabName } from './accountsSlice';

async function signOut() {
  try {
    await Auth.signOut({ global: true });
    window.location.href = window.location.origin;
  } catch (error) {
    throw new Error(String(error));
  }
}

const Accounts = () => {
  const currentTabName = useAppSelector(
    (state) => state.accountsReducer.currentTabName,
  );
  const dispatch = useAppDispatch();
  const [showAccount, setShowAccount] = useState(
    currentTabName === 'Your Account',
  );
  const [showPermissions, setShowPermissions] = useState(
    currentTabName === 'Your Team',
  );
  const [session, setSession] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      setSession(res);
    };
    fetchData();
  }, []);

  let name;
  let firstInitial;
  let lastInitial;
  let email;
  let company;
  let role;
  if (session) {
    name = session.signInUserSession.idToken.payload.name;
    email = session.signInUserSession.idToken.payload.email;
    company = /(?<=@)[^.]+(?=\.)/.exec(email);
    firstInitial = name.split('')[0];
    lastInitial = name.split('')[name.split('').lastIndexOf(' ') + 1];
    role = JSON.parse(
      session.signInUserSession.idToken.payload['https://hasura.io/jwt/claims'],
    )['X-Hasura-Role'];
  }
  return (
    <div className="h-screen w-full overflow-auto pt-16 pl-10">
      <div className="w-11/12">
        <div className="mb-6 flex justify-between">
          <h1 className="text-5xl font-bold">Your Account</h1>
          <button
            className="h-12 w-1/12 min-w-fit rounded-lg bg-relay-blue-500 px-10 text-center text-lg text-white hover:bg-relay-blue-600"
            onClick={() => signOut()}
          >
            Sign Out
          </button>
        </div>
        <div className="mb-10 flex">
          <div className="flex h-28 w-28 items-center justify-center rounded-full bg-relay-blue-500 text-4xl text-white">
            {firstInitial}
            {lastInitial}
          </div>
          <div className="flex-col space-y-1 pl-4 pt-6">
            <span className="block text-2xl">{name}</span>
            <span className="block text-xl">{company}</span>
          </div>
        </div>
        <div className="mb-10 flex">
          <button
            type="button"
            // className="min-w-fit border-b-2 px-4 pb-2 text-xl hover:border-b-2 hover:border-relay-blue-500 hover:text-relay-blue-500"
            className={classNames(
              currentTabName === 'Your Account'
                ? 'border-b-2 border-relay-blue-500 text-relay-blue-500'
                : 'group-hover:bg-relay-grey-200',
              'min-w-fit border-b-2 px-4 pb-2 text-xl',
            )}
            onClick={(e) => {
              e.preventDefault();
              dispatch(switchCurrentTabName('Your Account'));
              setShowAccount(true);
              setShowPermissions(false);
            }}
          >
            Your Account
          </button>
          <button
            type="button"
            // className="min-w-fit border-b-2 px-4 pb-2 text-xl hover:border-b-2 hover:border-relay-blue-500 hover:text-relay-blue-500"
            className={classNames(
              currentTabName === 'Your Team'
                ? 'border-b-2 border-relay-blue-500 text-relay-blue-500'
                : 'group-hover:bg-relay-grey-200',
              'min-w-fit border-b-2 px-4 pb-2 text-xl',
            )}
            onClick={(e) => {
              e.preventDefault();
              dispatch(switchCurrentTabName('Your Team'));
              setShowPermissions(true); // TODO enum
              setShowAccount(false);
            }}
          >
            Your Team
          </button>
          <div className="w-full border-b-2"></div>
        </div>
        {session && showAccount && (
          <UserAccount data={{ name, email, company, role }} />
        )}
        {session && showPermissions && (
          <TeamPermissions data={{ email, role }} />
        )}
      </div>
    </div>
  );
};

export default Accounts;
