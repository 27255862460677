import { useEffect, useRef, useState } from 'react';
import {
  CognitoIdentityProviderClient,
  CognitoIdentityProviderClientConfig,
  UpdateUserAttributesCommand,
  UpdateUserAttributesCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import { Auth } from 'aws-amplify';

interface UserAccountProps {
  data: {
    name: any;
    email: any;
    company: any;
    role: any;
  };
}

const UserAccount = ({
  data: { name, email, company, role },
}: UserAccountProps) => {
  const config: CognitoIdentityProviderClientConfig = { region: 'US-EAST-1' };
  const client = new CognitoIdentityProviderClient(config);
  let newNameInput: UpdateUserAttributesCommandInput;
  let newEmailInput: UpdateUserAttributesCommandInput;
  let newNameCommand: UpdateUserAttributesCommand;
  let newEmailCommand: UpdateUserAttributesCommand;
  const [session, setSession] = useState<any>();
  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const originalName = useRef(name);
  const originalEmail = useRef(email);
  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setSession(res);
    };
    fetchData();
  }, []);
  if (session) {
    newNameInput = {
      UserAttributes: [
        {
          Name: 'name',
          Value: newName,
        },
      ],
      AccessToken: session.signInUserSession.accessToken.jwtToken || '',
    };
    // newEmailInput = {
    //   UserAttributes: [
    //     {
    //       Name: 'email',
    //       Value: newEmail,
    //     },
    //   ],
    //   AccessToken: session.signInUserSession.accessToken.jwtToken || '',
    // };
    newNameCommand = new UpdateUserAttributesCommand(newNameInput);
    // newEmailCommand = new UpdateUserAttributesCommand(newEmailInput);
  }
  const handleOnClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (originalName !== newName) {
      try {
        const response = await client.send(newNameCommand);
      } catch (e) {
        throw new Error(String(e));
      }
    } /*else if (originalEmail !== newEmail) {*/
    //   try {
    //     const response = await client.send(newEmailCommand);
    //   } catch (e) {
    //     throw new Error(String(e));
    //   }
    // } else if (originalEmail !== newEmail && originalName !== newName) {
    //   try {
    //     const response1 = await client.send(newNameCommand);
    //     const response2 = await client.send(newEmailCommand);
    //   } catch (e) {
    //     throw new Error(String(e));
    //   }
    // }
  };
  return (
    <div className="flex h-full w-full space-x-20">
      <div className="w-5/12 min-w-fit space-y-4">
        <div>
          <label htmlFor="name" className="block text-xl">
            Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={name}
              onChange={(e) => setNewName(e.target.value)}
              className="block h-12 w-full rounded-md border border-relay-grey-200 bg-relay-grey-100 p-2 text-lg focus:outline-none"
              placeholder="Your Name"
            />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-xl">
            Email
          </label>
          <div className="mt-1">
            <input
              type="email"
              name="email"
              id="email"
              defaultValue={email}
              readOnly={true}
              // onChange={(e) => setNewEmail(e.target.value)}
              className="border-relay-grey-200 text-relay-grey-400 block h-12 w-full rounded-md border bg-relay-grey-100 p-2 text-lg focus:outline-none"
              placeholder="you@example.com"
            />
          </div>
        </div>
        {(originalEmail.current !== newEmail ||
          originalName.current !== newName) && (
          <button
            className="my-2 h-10 w-full min-w-fit rounded-lg bg-relay-blue-500 px-4 text-lg  text-white active:bg-relay-blue-600"
            onClick={(e) => handleOnClick(e)}
          >
            Submit
          </button>
        )}
      </div>
      <div className="w-5/12 min-w-fit space-y-4">
        <div>
          <label htmlFor="company" className="block text-xl">
            Company
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="company"
              id="company"
              readOnly={true}
              value={company}
              className="block h-12 w-full rounded-md border border-relay-grey-200 bg-relay-grey-100 p-2 text-lg text-relay-grey-400 focus:outline-none"
              placeholder="you@example.com"
            />
          </div>
        </div>
        <div>
          <label htmlFor="Permissions" className="block text-xl">
            Access Level
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="permissions"
              id="permissions"
              readOnly={true}
              value={role}
              className="block h-12  w-full rounded-md border border-relay-grey-200 bg-relay-grey-100 p-2 text-lg text-relay-grey-400 focus:outline-none"
              placeholder="you@example.com"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
