/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../app/services/types.generated';

import { api } from '../../../app/services/hasuraBaseApi';
export type GetAllAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllAccountsQuery = { __typename?: 'query_root', account: Array<{ __typename?: 'account', name: string, id: any }> };


export const GetAllAccountsDocument = `
    query GetAllAccounts {
  account {
    name
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAllAccounts: build.query<GetAllAccountsQuery, GetAllAccountsQueryVariables | void>({
      query: (variables) => ({ document: GetAllAccountsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllAccountsQuery, useLazyGetAllAccountsQuery } = injectedRtkApi;

