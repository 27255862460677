/**
 * ************************************
 *
 * @module store.ts
 * @author Adam Joesten
 * @date 6/15/2021
 * @description Redux 'single source of truth'
 *
 * ************************************
 */

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import { setupListeners } from '@reduxjs/toolkit/query/react';
import rootReducer from './index';
import { api as hasuraBaseApi } from './services/hasuraBaseApi';
import { api as eRelayBidBaseApi } from './services/eRelayBidBaseApi';
import { api as reportingBaseApi } from './services/reportingBaseApi';
// import { logger } from 'redux-logger'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      hasuraBaseApi.middleware,
      eRelayBidBaseApi.middleware,
      reportingBaseApi.middleware,
    ),
});

// setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
