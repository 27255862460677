import React, {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react';

interface SplitPaneProps {
  left:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal;
  right:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal;
}

const SplitPane = (props: SplitPaneProps) => {
  return (
    <div id="split-plane" className="flex h-screen w-screen">
      <div
        id="navigation-view"
        className="flex"
      >
        {props.left}
      </div>
      <div id="app-view" className="flex-1 overflow-auto">
        {props.right}
      </div>
    </div>
  );
};

export default SplitPane;
