import { ReactComponent as CodeIcon } from '../../assets/code-icon.svg';
import classNames from '../../utils/tailwind/classNames';
import { ReactComponent as DollarSignIcon } from '../../assets/dollar-sign.svg';
import { ReactComponent as SupplyGroupsIcon } from '../../assets/supply-icon.svg';
import { ReactComponent as Placements } from '../../assets/Placements.svg';
import { ReactComponent as LinkIcon } from '../../assets/LinkIcon.svg';
import { ReactComponent as SupplyApprovalIcon } from '../../assets/file-clock.svg';

export interface TabType {
  // Accounts and Documentation aren't defined in the tab list below but they still need to be part of this type
  name:
  | 'Reporting'
  | 'Filtering'
  | 'Accounts'
  | 'Documentation'
  | 'Deals'
  | 'Supply'
  | 'Supply Approval'
  | 'Demand';
  href: string;
  count: number;
  external: boolean;
  icon: (currentTabName: String) => JSX.Element;
}

// Define new tabs here
export const Tabs: TabType[] = [
  {
    name: 'Reporting',
    href: '/relay/reporting',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
        <DollarSignIcon
          className={classNames(
            currentTabName === 'Reporting'
              ? 'fill-relay-blue-500 stroke-relay-blue-500'
              : 'fill-black stroke-black',
            'stroke-0',
          )}
        />
      );
    },
  },
  {
    name: 'Filtering',
    href: '/relay/filtering',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
        <CodeIcon
          className={classNames(
            currentTabName === 'Filtering'
              ? 'fill-relay-blue-500 stroke-relay-blue-500'
              : 'fill-black stroke-black',
            'stroke-0',
          )}
        />
      );
    },
  },
  {
    name: 'Deals',
    href: '/relay/deals',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
        <Placements
          className={classNames(
            currentTabName === 'Deals'
              ? 'fill-relay-blue-500 stroke-relay-blue-500'
              : 'fill-black stroke-black',
            'stroke-0',
          )}
        />
      );
    },
  },
  {
    name: 'Supply',
    href: '/relay/supply',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
        <SupplyGroupsIcon
          className={classNames(
            currentTabName === 'Supply'
              ? 'fill-relay-blue-500 stroke-relay-blue-500'
              : 'fill-black stroke-black',
            'stroke-0',
          )}
        />
      );
    },
  },
  {
    name: 'Supply Approval',
    href: '/relay/supply_approval',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
          <SupplyApprovalIcon
              className={classNames(
                  currentTabName === 'Supply Approval'
                      ? 'stroke-relay-blue-500'
                      : 'stroke-black',
                  'stroke-0',
              )}
          />
      );
    },
  },
  {
    name: 'Demand',
    href: '/relay/demand',
    count: 0,
    external: false,
    icon: (currentTabName: String) => {
      return (
        <LinkIcon
          className={classNames(
            currentTabName === 'Demand'
              ? 'stroke-relay-blue-500'
              : 'stroke-black',
            'stroke-0',
          )}
        />
      );
    },
  },
];
